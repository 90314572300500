import React, {Component} from "react";
import logo from './logo.svg';
import './App.css';

export default class App extends Component {
    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo"/>
                    <h3>Building...</h3>
                </header>
                <footer>
                    <a href="https://beian.miit.gov.cn" target="_blank" rel="noreferrer">辽ICP备 2021010463 号</a>
                </footer>
            </div>
        );
    }
}
